$primary-color: #ffd700;



@import 'animate.css';
// imports the animate.css library



// this imports the "loaders" package to use the pacman loader.
@import '~loaders.css/src/animations/pacman.scss';



// imports fonts from font-face
@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf')
  // .ttf is the fonts file type.
}



@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2')
  // .woff2 is the fonts file type.
}



@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2')
  // .woff2 is the fonts file type.
}



input,
textarea {

  font-family: 'Helvetica Neue';

}



.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}